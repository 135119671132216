import * as React from 'react';
import {Helmet} from "react-helmet";
 
class Allsongs extends React.Component<IAppProps, IAppState> {
	constructor(props: IAppProps) {
		super(props);
		this.state = { songs: []};
		this.formatTextT = this.formatTextT.bind(this);
		this.formatTextE = this.formatTextE.bind(this);
		this.concatStr = this.concatStr.bind(this);
		this.createMarkup = this.createMarkup.bind(this);
	}

	async componentDidMount() {
		try {
			let r = await fetch("https://r2k0yhk5oi.execute-api.us-west-2.amazonaws.com/latest/api/songs");
			let songs = await r.json();
			this.setState({ songs });
		} catch (error) {
			console.log(error);
		}

		
	}

	createMarkup(input: string) {
		let tmp = input.replace("\\|", "<br");
		return {__html: tmp};
	  }
	  
	MyComponent(input: string) {
		return <div dangerouslySetInnerHTML={this.createMarkup(input)} />;
	}

	formatTextT(input: string) {
		
	return (<p className="small tmode" dangerouslySetInnerHTML={this.createMarkup(input)}></p>);
	}

	

	formatTextE(input: string) {
		
		return (<p className="small ntmode" hidden={true} dangerouslySetInnerHTML={this.createMarkup(input)} ></p>);
	}

	concatStr(input: string, input2: string) {
		return input+input2;
	}
	
	
	render() {
		return (
			<div className="container callsongs">
				<Helmet>
					<meta charSet="utf-8" />
					<title>கிறிஸ்தவ பாடல்கள் - Christian Songs</title>
					<meta name="description" content="எல்லா தமிழ் கிறிஸ்தவ பாடல்கள் இங்கு தரப்பட்டுள்ளது. All Tamil Christian songs are listed here." />
					<link rel="canonical" href="https://www.mytamilsongs.net/allsongs" />
				</Helmet>
				<h5 className="mt-2 tmode" id="content"><strong>கிறிஸ்தவ பாடல்கள்</strong></h5>
				<h5 className="mt-2 ntmode" hidden={true} id="content"><strong>Christian Songs</strong></h5>
				<p className="small tmode">எல்லா தமிழ் கிறிஸ்தவ பாடல்கள் இங்கு தரப்பட்டுள்ளது.</p>
				<p className="small ntmode" hidden={true} >All Tamil Christian songs are listed here.</p>
				
				<div id="accordion">{this.state.songs.map((song, index) => {
					return (
						<div key={index}>
							<div className="card mb-1 bg-light shadow">
								<div className="card-header " id={`songhead${song.id}`}>
								  <h6 className="mb-0 small"> 
									<button className="btn btn-sm btn-block collapsed cardbtn" data-toggle="collapse" data-target={`#songbody${song.id}`} aria-expanded="false" aria-controls={`songbody${song.id}`}>
										<span className="float-left tmode" ><strong>{song.song_title}</strong></span>
										<span className="float-left ntmode" hidden={true}><strong>{song.transliterated_title}</strong></span>
									</button>
								  </h6>
								</div>
							
								<div id={`songbody${song.id}`} className="panel-collapse collapse" aria-labelledby={`songhead${song.id}`} data-parent="#accordion">
								  <div className="card-body">
									<h5 className="tmode" id="content"><strong>{song.song_title}</strong></h5>
									<h5 className="ntmode" hidden={true} id="content"><strong>{song.transliterated_title}</strong></h5>
									<p className="small">{song.song_desc}</p>
									
									{this.formatTextT(song.lyrics_tamil)}
									{this.formatTextE(song.lyrics_english)}
									
								  </div>
								</div>
							</div>
						</div>
					)
				})}
				</div>
			</div>
		);
	}
}
 
export interface IAppProps {}

export interface IAppState {
	songs: Array<{id: string, song_title: string, lyrics_tamil: string, lyrics_english: string, 
	transliterated_title: string, song_desc: string, search_song: string, song_tags: string, song_book_nbr: string}>;
}
export default Allsongs;