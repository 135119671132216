import * as React from 'react';
 
class Home extends React.Component<IAppProps, IAppState> {

	render() {
		return (
		  <div></div>
		);
	}
}
 
export interface IAppProps {}

export interface IAppState {
	
}
export default Home;