import * as React from 'react';
import * as CSS from 'csstype';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import Home from "./Home";
import Transliteration from "./Transliteration";
import Allsongs from "./Allsongs";
import Tagsongs from "./Tagsongs";
import Onesong from "./Onesong";
import Disclaimer from "./Disclaimer";

import {Helmet} from "react-helmet";



const menuicon: CSS.Properties = {
	fontSize:'2em', cursor:'pointer'
};


class App extends React.Component<IAppProps, IAppState> {
	

	constructor(props: IAppProps) {
		super(props);
		this.state = { songs: []};
		// This binding is necessary to make `this` work in the callback
		
	}

	
	async componentDidMount() {
		//we are allowing cross origin access.
		let url = "https://r2k0yhk5oi.execute-api.us-west-2.amazonaws.com/latest/api/getsearchdata";
		try {
			let r = await fetch(url);
			let songs = await r.json();
			this.setState({ songs });
		} catch (error) {
			console.log(error);
		}
			
	}

		

	render() {
		return (
			<Router>
				
				<div id="mySidenav" className="sidenav">
					<a href="javascript:void(0)" className="closebtn" id="closebtn" >&times;&nbsp;&nbsp;</a>
					
					<Link to="/" className="tmode">வீடு</Link>
					<Link to="/" className="ntmode" hidden={true}>Home</Link>
					
					<Link to="/transliteration" className="tmode" >ஒலிபெயர்ப்பு சேவை</Link>
					<Link to="/transliteration" className="ntmode" hidden={true}>Transliteration Service</Link>
					
					<Link to="/allsongs" className="tmode" >கிறிஸ்தவ பாடல்கள்</Link>
					<Link to="/allsongs" className="ntmode" hidden={true}>Christian Songs</Link>
					
					<Link to="/tags/paamalai" className="tmode">பாமாலைகள்</Link>
					<Link to="/tags/paamalai" className="ntmode" hidden={true}>Hymns</Link>
					
					<Link to="/tags/keerthanai" className="tmode">ஞான கீர்த்தனைகள்</Link>
					<Link to="/tags/keerthanai" className="ntmode" hidden={true}>Wisdom Lyrics</Link>

					<Link to="/tags/jebathottaJeyageethangal" className="tmode">ஜெபதோட்ட ஜெயகீதங்கள்</Link>
					<Link to="/tags/jebathottaJeyageethangal" className="ntmode" hidden={true}>Jebathotta Jeyageethangal</Link>
					
					<Link to="/tags/callOfGod" className="tmode">தேவ அழைப்பு குறித்த பாடல்கள்</Link>
					<Link to="/tags/callOfGod" className="ntmode" hidden={true}>Songs on God's Call</Link>
					
					<Link to="/tags/praise" className="tmode">துதி பாடல்கள்</Link>
					<Link to="/tags/praise" className="ntmode" hidden={true}>Praise Songs</Link>
					
					<Link to="/tags/worship" className="tmode">ஆராதனைப் பாடல்கள்</Link>
					<Link to="/tags/worship" className="ntmode" hidden={true}>Worship Songs</Link>
					
					<Link to="/tags/prayer" className="tmode">ஜெபம் குறித்த  பாடல்கள்</Link>
					<Link to="/tags/prayer" className="ntmode" hidden={true}>Songs on Prayer</Link>    
					
					<Link to="/tags/wordOfGod" className="tmode">வேதாகமம் குறித்த  பாடல்கள்</Link>
					<Link to="/tags/wordOfGod" className="ntmode" hidden={true}>Songs on Scripture</Link>
					
					<Link to="/tags/repentanceAndConfession" className="tmode">பாவ அறிக்கை, மனம்திரும்புதல் குறித்த  பாடல்கள்</Link>
					<Link to="/tags/repentanceAndConfession" className="ntmode" hidden={true}>Songs on Repentance and Confession</Link>
					
					<Link to="/tags/faithAndAssurance" className="tmode">விசுவாசம், நம்பிக்கை குறித்த பாடல்கள்</Link>
					<Link to="/tags/faithAndAssurancei" className="ntmode" hidden={true}>Songs on Faith and Assurance</Link>
					
					<Link to="/tags/lifeInChristAndGuidance" className="tmode">இயேசுவில் வாழ்வு, வழிநடத்துதல் குறித்த பாடல்கள்</Link>
					<Link to="/tags/lifeInChristAndGuidance" className="ntmode" hidden={true}>Songs on Life in Christ and Guidance</Link>
					
					<Link to="/tags/graceAndBlessing" className="tmode">கிருபை, தேவாசீர்வாதம் குறித்த பாடல்கள்</Link>
					<Link to="/tags/graceAndBlessing" className="ntmode" hidden={true}>Songs on Grace and Blessing of God</Link>
					
					<Link to="/tags/joy" className="tmode">சந்தோஷம்   குறித்த  பாடல்கள்</Link>
					<Link to="/tags/joy" className="ntmode" hidden={true}>Songs on Joy</Link>
					
					<Link to="/tags/loveAndFellowship" className="tmode">அன்பு, ஐக்கியம் குறித்த பாடல்கள்</Link>
					<Link to="/tags/loveAndFellowship" className="ntmode" hidden={true}>Songs on Love and Fellowship</Link>
					
					<Link to="/tags/surrenderAndSanctification" className="tmode">ஒப்புக்கொடுத்தல், பரிசுத்தமாகுதல் குறித்த பாடல்கள்</Link>
					<Link to="/tags/surrenderAndSanctification" className="ntmode" hidden={true}>Songs on Surrender and Sanctification</Link>
					
					<Link to="/tags/followingChrist" className="tmode">கிறிஸ்துவை பின்பற்றுதல் குறித்த பாடல்கள்</Link>
					<Link to="/tags/followingChrist" className="ntmode" hidden={true}>Songs on Following Christ</Link>
					
					<Link to="/tags/evangelismAndMissions" className="tmode">நற்செய்தி பணி, மிஷனரி ஊழியம் குறித்த பாடல்கள்</Link>
					<Link to="/tags/evangelismAndMissions" className="ntmode" hidden={true}>Songs on Evangelism and Missions</Link>
					
					<Link to="/tags/secondComing" className="tmode">இயேசுவின் இரண்டாம் வருகை, பரலோகம் குறித்த பாடல்கள்</Link>
					<Link to="/tags/secondComing" className="ntmode" hidden={true}>Songs on Second Coming of Jesus Christ and Heaven</Link>
					
					<Link to="/tags/church" className="tmode">சபை குறித்த பாடல்கள்</Link>
					<Link to="/tags/church" className="ntmode" hidden={true}>Songs on the Church</Link>
					
					<Link to="/tags/communion" className="tmode">திருவிருந்து குறித்த பாடல்கள்</Link>
					<Link to="/tags/communion" className="ntmode" hidden={true}>Songs on Holy Communion</Link>
					
					<Link to="/tags/children" className="tmode">சிறுபிள்ளைகள்  குறித்த பாடல்கள்</Link>
					<Link to="/tags/children" className="ntmode" hidden={true}>Songs on Children</Link>
					
					<Link to="/tags/wedding" className="tmode">திருமணம்  குறித்த பாடல்கள்</Link>
					<Link to="/tags/wedding" className="ntmode" hidden={true}>Songs on Wedding</Link>
					
					<Link to="/tags/death" className="tmode">மரணம்  குறித்த பாடல்கள்</Link>
					<Link to="/tags/death" className="ntmode" hidden={true}>Songs on Death</Link>
					
					<Link to="/tags/newYear" className="tmode">புது வருடம்  குறித்த பாடல்கள்</Link>
					<Link to="/tags/newYear" className="ntmode" hidden={true}>Songs on New Year</Link>
					
					<Link to="/tags/christmas" className="tmode">கிறிஸ்து பிறப்பு  குறித்த பாடல்கள்</Link>
					<Link to="/tags/christmas" className="ntmode" hidden={true}>Songs on Christmas</Link>
					
					<Link to="/tags/palmSunday" className="tmode">குருத்தோலை ஞாயிறு  குறித்த பாடல்கள்</Link>
					<Link to="/tags/palmSundayi" className="ntmode" hidden={true}>Songs on Palm Sunday</Link>
					
					<Link to="/tags/sufferingAndDeathOfChrist" className="tmode">கிறிஸ்து பாடு மரணம்  குறித்த பாடல்கள்</Link>
					<Link to="/tags/sufferingAndDeathOfChrist" className="ntmode" hidden={true}>Songs on Suffering and death of Christ</Link>
					
					<Link to="/tags/resurrectionOfChrist" className="tmode">கிறிஸ்து உயிர்த்தெழுதல்  குறித்த பாடல்கள்</Link>
					<Link to="/tags/resurrectionOfChrist" className="ntmode" hidden={true}>Songs on Ressurection of Christ</Link>
					
					<Link to="/tags/farewell" className="tmode">பிரிவு உபசாரம்  குறித்த பாடல்கள்</Link>
					<Link to="/tags/farewell" className="ntmode" hidden={true}>Songs on Farewell</Link>

					<Link to="/disclaimer" className="tmode">மறுப்பு அறிக்கை</Link>
					<Link to="/disclaimer" className="ntmode" hidden={true}>Disclaimer statement</Link>
				</div>
				<div id="main" style={{padding:0,margin:0}}>
					<Helmet>
						<meta charSet="utf-8" />
						<title>என் தமிழ் பாடல்கள் - My Tamil Songs</title>
						<meta name="description" content="My Tamil Songs Website gives an easy way to search tamil christian song lyrics and their transliterated text. The Songs are also grouped into different categories of interest." />
						<link rel="canonical" href="https://www.mytamilsongs.net/" />
					</Helmet>
					<nav className="navbar tnavbar-vlblue-fg tnavbar-hblue-bg headupper fixed-top justify-content-between ">
						<span id="openbtn" style={menuicon} >☰&nbsp;</span>
						<a className="hhover tnavbar-vlblue-fg tmode"  href="/tamilservices/home"><strong>என் தமிழ் பாடல்கள்</strong></a>
						<a className="hhover tnavbar-vlblue-fg ntmode" hidden={true} href="/tamilservices/home"><strong>My Tamil Songs</strong></a>
						<button className="btn tnavbar-hblue-fg tnavbar-vlblue-bg my-2 my-sm-0 tmbtn " id="tamilMode" >English Mode</button>
					</nav>
					<nav className="navbar tnavbar-vlblue-bg tnavbar-vlblue-fg headunder">	
						<span style={menuicon} >☰&nbsp;</span>
						<a className="hhover tnavbar-vlblue-fg tmode" href="/tamilservices/home"><strong>Tamil Services</strong></a>
						<button className="btn tnavbar-vlblue-fg tnavbar-vlblue-bg my-2 my-sm-0 "  id="tamilModeUnder">English Mode</button>
					</nav>
					<nav className="navbar tnavbar-vlblue-bg tnavbar-hblue-fg ">	
						<form className="form-inline col-md-12 formsearch" action="/"  accept-charset="UTF-8" method="get" id="searchform" name="searchform">
							<div className="ui-widget col-md-12">
								<input className="form-control  w-100 col-md-12" id="searchtags" type="search" placeholder="பாடல்களை இங்கு  தேடு. Search the songs here." aria-label="Search" />
							</div>
						</form>
					</nav>
					<div className="content" id="wrapper">
						<Route exact path="/" component={Home}/>
						<Route path="/transliteration" component={Transliteration}/>
						<Route path="/allsongs" component={Allsongs}/>
						<Route path="/tags/:tag" component={Tagsongs}/>
						<Route path="/songs/:nbr" component={Onesong}/>
						<Route path="/disclaimer" component={Disclaimer}/>
					</div>
				</div>

				<div className="container mt-2 chome">
					<div className="row">
						<div className="col-md-4 ">
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/transliteration" className="tmode btn-block">ஒலிபெயர்ப்பு சேவை</Link>
									<Link to="/transliteration" className="ntmode btn-block" hidden={true}>Transliteration Service</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/allsongs" className="tmode btn-block">கிறிஸ்தவ பாடல்கள்</Link>
									<Link to="/allsongs" className="ntmode btn-block" hidden={true}>Christian Songs</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/paamalai" className="tmode btn-block">பாமாலைகள்</Link>
									<Link to="/tags/paamalai" className="ntmode btn-block" hidden={true}>Hymns</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/keerthanai" className="tmode btn-block">ஞான கீர்த்தனைகள்</Link>
									<Link to="/tags/keerthanai" className="ntmode btn-block" hidden={true}>Wisdom Lyrics</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/jebathottaJeyageethangal" className="tmode btn-block">ஜெபதோட்ட ஜெயகீதங்கள்</Link>
									<Link to="/tags/jebathottaJeyageethangal" className="ntmode btn-block" hidden={true}>Jebathotta Jeyageethangal</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/callOfGod" className="tmode btn-block">தேவ அழைப்பு குறித்த பாடல்கள்</Link>
									<Link to="/tags/callOfGod" className="ntmode btn-block" hidden={true}>Songs on God's Call</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/praise" className="tmode btn-block">துதி பாடல்கள்</Link>
									<Link to="/tags/praise" className="ntmode btn-block" hidden={true}>Praise Songs</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/worship" className="tmode btn-block">ஆராதனைப் பாடல்கள்</Link>
									<Link to="/tags/worship" className="ntmode btn-block" hidden={true}>Worship Songs</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/prayer" className="tmode btn-block">ஜெபம் குறித்த  பாடல்கள்</Link>
									<Link to="/tags/prayer" className="ntmode btn-block" hidden={true}>Songs on Prayer</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/wordOfGod" className="tmode btn-block">வேதாகமம் குறித்த  பாடல்கள்</Link>
									<Link to="/tags/wordOfGod" className="ntmode btn-block" hidden={true}>Songs on Scripture</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/repentanceAndConfession" className="tmode btn-block">பாவ அறிக்கை, மனம்திரும்புதல் குறித்த  பாடல்கள்</Link>
									<Link to="/tags/repentanceAndConfession" className="ntmode btn-block" hidden={true}>Songs on Repentance and Confession</Link>
								</div>
							</div>
						</div>
						
						<div className="col-md-4 ">
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/faithAndAssurance" className="tmode btn-block">விசுவாசம், நம்பிக்கை குறித்த பாடல்கள்</Link>
									<Link to="/tags/faithAndAssurancei" className="ntmode btn-block" hidden={true}>Songs on Faith and Assurance</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/lifeInChristAndGuidance" className="tmode btn-block">இயேசுவில் வாழ்வு, வழிநடத்துதல் குறித்த பாடல்கள்</Link>
									<Link to="/tags/lifeInChristAndGuidance" className="ntmode btn-block" hidden={true}>Songs on Life in Christ and Guidance</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/graceAndBlessing" className="tmode btn-block">கிருபை, தேவாசீர்வாதம் குறித்த பாடல்கள்</Link>
									<Link to="/tags/graceAndBlessing" className="ntmode btn-block" hidden={true}>Songs on Grace and Blessing of God</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/joy" className="tmode btn-block">சந்தோஷம்   குறித்த  பாடல்கள்</Link>
									<Link to="/tags/joy" className="ntmode btn-block" hidden={true}>Songs on Joy</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/loveAndFellowship" className="tmode btn-block">அன்பு, ஐக்கியம் குறித்த பாடல்கள்</Link>
									<Link to="/tags/loveAndFellowship" className="ntmode btn-block" hidden={true}>Songs on Love and Fellowship</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/surrenderAndSanctification" className="tmode btn-block">ஒப்புக்கொடுத்தல், பரிசுத்தமாகுதல் குறித்த பாடல்கள்</Link>
									<Link to="/tags/surrenderAndSanctification" className="ntmode btn-block" hidden={true}>Songs on Surrender and Sanctification</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/followingChrist" className="tmode btn-block">கிறிஸ்துவை பின்பற்றுதல் குறித்த பாடல்கள்</Link>
									<Link to="/tags/followingChrist" className="ntmode btn-block" hidden={true}>Songs on Following Christ</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/evangelismAndMissions" className="tmode btn-block">நற்செய்தி பணி, மிஷனரி ஊழியம் குறித்த பாடல்கள்</Link>
									<Link to="/tags/evangelismAndMissions" className="ntmode btn-block" hidden={true}>Songs on Evangelism and Missions</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/secondComing" className="tmode btn-block">இயேசுவின் இரண்டாம் வருகை, பரலோகம் குறித்த பாடல்கள்</Link>
									<Link to="/tags/secondComing" className="ntmode btn-block" hidden={true}>Songs on Second Coming of Jesus Christ and Heaven</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/church" className="tmode btn-block">சபை குறித்த பாடல்கள்</Link>
									<Link to="/tags/church" className="ntmode btn-block" hidden={true}>Songs on the Church</Link>
								</div>
							</div>
						</div>
						
						<div className="col-md-4 ">
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/communion" className="tmode btn-block">திருவிருந்து குறித்த பாடல்கள்</Link>
									<Link to="/tags/communion" className="ntmode btn-block" hidden={true}>Songs on Holy Communion</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/children" className="tmode btn-block">சிறுபிள்ளைகள்  குறித்த பாடல்கள்</Link>
									<Link to="/tags/children" className="ntmode btn-block" hidden={true}>Songs on Children</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/wedding" className="tmode btn-block">திருமணம்  குறித்த பாடல்கள்</Link>
									<Link to="/tags/wedding" className="ntmode btn-block" hidden={true}>Songs on Wedding</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/death" className="tmode btn-block">மரணம்  குறித்த பாடல்கள்</Link>
									<Link to="/tags/death" className="ntmode btn-block" hidden={true}>Songs on Death</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/newYear" className="tmode btn-block">புது வருடம்  குறித்த பாடல்கள்</Link>
									<Link to="/tags/newYear" className="ntmode btn-block" hidden={true}>Songs on New Year</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/christmas" className="tmode btn-block">கிறிஸ்து பிறப்பு  குறித்த பாடல்கள்</Link>
									<Link to="/tags/christmas" className="ntmode btn-block" hidden={true}>Songs on Christmas</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/palmSunday" className="tmode btn-block">குருத்தோலை ஞாயிறு  குறித்த பாடல்கள்</Link>
									<Link to="/tags/palmSundayi" className="ntmode btn-block" hidden={true}>Songs on Palm Sunday</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/sufferingAndDeathOfChrist" className="tmode btn-block">கிறிஸ்து பாடு மரணம்  குறித்த பாடல்கள்</Link>
									<Link to="/tags/sufferingAndDeathOfChrist" className="ntmode btn-block" hidden={true}>Songs on Suffering and death of Christ</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/resurrectionOfChrist" className="tmode btn-block">கிறிஸ்து உயிர்த்தெழுதல்  குறித்த பாடல்கள்</Link>
									<Link to="/tags/resurrectionOfChrist" className="ntmode btn-block" hidden={true}>Songs on Ressurection of Christ</Link>
								</div>
							</div>
							<div className="card mb-2 bg-light shadow">
								<div className="card-body tscard">
									<Link to="/tags/farewell" className="tmode btn-block">பிரிவு உபசாரம்  குறித்த பாடல்கள்</Link>
									<Link to="/tags/farewell" className="ntmode btn-block" hidden={true}>Songs on Farewell</Link>
								</div>
							</div>
						</div>
					
					</div>
				
				</div>

				<div className="container mt-2 csearch" hidden={true}>
					{this.state.songs.map((song, index) => { 
						return(
							<div key={index}>
								<Link to={`/songs/${song.song_book_nbr}`} id={song.song_book_nbr}>{song.song_title}</Link>
							</div>
						)
					})}
					<Link to="/transliteration" id="transliteration" >ஒலிபெயர்ப்பு சேவை</Link>
					<Link to="/allsongs" id="allsongs">கிறிஸ்தவ பாடல்கள்</Link>
					<Link to="/tags/paamalai" id="paamalai">பாமாலைகள்</Link>
					<Link to="/tags/keerthanai" id="keerthanai">ஞான கீர்த்தனைகள்</Link>
					<Link to="/tags/jebathottaJeyageethangal" id="jebathottaJeyageethangal">ஜெபதோட்ட ஜெயகீதங்கள்</Link>
					<Link to="/tags/callOfGod" id="callOfGod">தேவ அழைப்பு குறித்த பாடல்கள்</Link>
					<Link to="/tags/praise" id="praise">துதி பாடல்கள்</Link>
					<Link to="/tags/worship" id="worship">ஆராதனைப் பாடல்கள்</Link>
					<Link to="/tags/prayer" id="prayer">ஜெபம் குறித்த  பாடல்கள்</Link>
					<Link to="/tags/wordOfGod" id="wordOfGod">வேதாகமம் குறித்த  பாடல்கள்</Link>
					<Link to="/tags/repentanceAndConfession" id="repentanceAndConfession">பாவ அறிக்கை, மனம்திரும்புதல் குறித்த  பாடல்கள்</Link>
					<Link to="/tags/faithAndAssurance" id="faithAndAssurance">விசுவாசம், நம்பிக்கை குறித்த பாடல்கள்</Link>
					<Link to="/tags/lifeInChristAndGuidance" id="lifeInChristAndGuidance">இயேசுவில் வாழ்வு, வழிநடத்துதல் குறித்த பாடல்கள்</Link>
					<Link to="/tags/graceAndBlessing" id="graceAndBlessing">கிருபை, தேவாசீர்வாதம் குறித்த பாடல்கள்</Link>
					<Link to="/tags/joy" id="joy">சந்தோஷம்   குறித்த  பாடல்கள்</Link>
					<Link to="/tags/loveAndFellowship" id="loveAndFellowship">அன்பு, ஐக்கியம் குறித்த பாடல்கள்</Link>
					<Link to="/tags/surrenderAndSanctification" id="surrenderAndSanctification">ஒப்புக்கொடுத்தல், பரிசுத்தமாகுதல் குறித்த பாடல்கள்</Link>
					<Link to="/tags/followingChrist" id="followingChrist">கிறிஸ்துவை பின்பற்றுதல் குறித்த பாடல்கள்</Link>
					<Link to="/tags/evangelismAndMissions" id="evangelismAndMissions">நற்செய்தி பணி, மிஷனரி ஊழியம் குறித்த பாடல்கள்</Link>
					<Link to="/tags/secondComing" id="secondComing">இயேசுவின் இரண்டாம் வருகை, பரலோகம் குறித்த பாடல்கள்</Link>
					<Link to="/tags/church" id="church">சபை குறித்த பாடல்கள்</Link>
					<Link to="/tags/communion" id="communion">திருவிருந்து குறித்த பாடல்கள்</Link>
					<Link to="/tags/children" id="children">சிறுபிள்ளைகள்  குறித்த பாடல்கள்</Link>
					<Link to="/tags/wedding" id="wedding">திருமணம்  குறித்த பாடல்கள்</Link>
					<Link to="/tags/death" id="death">மரணம்  குறித்த பாடல்கள்</Link>
					<Link to="/tags/newYear" id="newYear">புது வருடம்  குறித்த பாடல்கள்</Link>
					<Link to="/tags/christmas" id="christmas">கிறிஸ்து பிறப்பு  குறித்த பாடல்கள்</Link>
					<Link to="/tags/palmSunday" id="palmSunday">குருத்தோலை ஞாயிறு  குறித்த பாடல்கள்</Link>
					<Link to="/tags/sufferingAndDeathOfChrist" id="sufferingAndDeathOfChrist">கிறிஸ்து பாடு மரணம்  குறித்த பாடல்கள்</Link>
					<Link to="/tags/resurrectionOfChrist" id="resurrectionOfChrist">கிறிஸ்து உயிர்த்தெழுதல்  குறித்த பாடல்கள்</Link>
					<Link to="/tags/farewell" id="farewell">பிரிவு உபசாரம்  குறித்த பாடல்கள்</Link>
					<Link to="/disclaimer" id="disclaimer">மறுப்பு அறிக்கை</Link>
					
				</div>
				
				<div id="valueHolderId" hidden={true}>{this.state.songs.map(song => { return song.id + "|"})}</div>
				<div id="value1HolderId" hidden={true}>{this.state.songs.map(song => { return song.search_song + "|"})}</div>
				<div id="value2HolderId" hidden={true}>{this.state.songs.map(song => { return song.song_book_nbr + "|"})}</div>
			</Router>
		);
	}
}

export interface IAppProps {}

export interface IAppState {
	songs: Array<{id: string, song_title: string, search_song: string, song_book_nbr: string}>;
	
}

export default App;
