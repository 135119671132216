import * as React from 'react';
import {Helmet} from "react-helmet";
 
class Transliteration extends React.Component<IAppProps, IAppState> {
	render() {
		return (
			<div className="container ctransliteration">
				<Helmet>
					<meta charSet="utf-8" />
					<title>ஒலிபெயர்ப்பு சேவை - Transliteration Service</title>
					<meta name="description" content="தமிழ் வார்த்தைகளை ஆங்கிலத்தில் ஒலிபெயர்ப்பு செய்து தரப்படும். Tamil unicode words will be transliterated in English." />
					<link rel="canonical" href="https://www.mytamilsongs.net/transliteration" />
				</Helmet>
				<h5 className="mt-2 tmode" id="content"><strong>ஒலிபெயர்ப்பு சேவை</strong></h5>
				<h5 className="mt-2 ntmode" hidden={true} id="content"><strong>Transliteration Service</strong></h5>
				<p className="small tmode">தமிழ் வார்த்தைகளை ஆங்கிலத்தில் ஒலிபெயர்ப்பு செய்து தரப்படும்.</p>
				<p className="small ntmode" hidden={true} >Tamil unicode words will be transliterated in English.</p>
				
				<div className="form-group">
				<p className="small pend tmode">தமிழ் வார்த்தைகளை இங்கே பதிவு செய்யுங்கள்.</p>
				<p className="small pend ntmode" hidden={true}>Type in the Tamil Text to be transliterated.</p>
				<textarea className="form-control  fontsize80 rounded-0" name="inputTA" id="inputTA" rows={6}></textarea>
				</div>
		
				<div className="form-group">
					<button type="button" className="btn btn-dark enableOnInput fontsize80 converttotamilnow tmode"  >ஒலிபெயர்ப்பு செய்</button>
					<button type="button" className="btn btn-dark enableOnInput fontsize80 converttotamilnow ntmode" hidden={true} >Transliterate Now</button>
				</div>
		
				<div className="form-group">
				<p className="small pend tmode">ஒலிபெயர்ப்பு செய்யப்பட்ட ஆங்கில வார்த்தைகள்.</p>
				<p className="small pend ntmode" hidden={true}>English Text of the Tamil Words that were transliterated.</p>
				
				<textarea className="form-control  fontsize80 rounded-0" name="outputTA" id="outputTA" rows={6}></textarea>
				</div>
				
				<div className="form-group">
					<button type="button" className="btn btn-dark enableOnOutput fontsize80 copytoclipboard tmode" >கிளிப்போர்டுக்கு நகலெடுக்கவும்</button>
					<button type="button" className="btn btn-dark enableOnOutput fontsize80 copytoclipboard ntmode" hidden={true} >Copy to Clipboard</button>
				</div>
			</div>
		);
	}
}
 
export interface IAppProps {}

export interface IAppState {
	
}
export default Transliteration;