import * as React from 'react';
 
class Disclaimer extends React.Component<IAppProps, IAppState> {

	render() {
		return (
		  <div className="container callsongs">
			  	<h5 className="mt-2 tmode" id="content"><strong>மறுப்பு அறிக்கை</strong></h5>
				<h5 className="mt-2 ntmode" hidden={true} id="content"><strong>Disclaimer statement</strong></h5>
				
				<p className="small " >All the lyrics of the Tamil Christian Songs and their transliterated text, published in this website is for general usage only. While we try to keep the website correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, services, contained on http://www.mytamilsongs.net for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>
				<p className="small " >In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of Tamil Songs or their transliterated texts.</p>
				<p className="small " >Every effort is made to keep mytamilsongs.net up and running smoothly. However, mytamilsongs.net takes no responsibility for, and will not be liable for, the service being temporarily unavailable due to technical issues beyond our control.</p>
				<p className="small " >The Website http://www.mytamilsongs.net is created for the benefit of anyone needing to use the lyrics of the Tamil Christian Songs and their transliterated text.</p>
		  </div>
		);
	}
}
 
export interface IAppProps {}

export interface IAppState {
	
}
export default Disclaimer;