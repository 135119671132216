import * as React from 'react';
import {Helmet} from "react-helmet";

class Onesong extends React.Component<IAppProps, IAppState> {
	constructor(props: IAppProps) {
		super(props);
		this.state = { songs: []};
		this.formatTextT = this.formatTextT.bind(this);
		this.formatTextE = this.formatTextE.bind(this);
		this.concatStr = this.concatStr.bind(this);
		this.createMarkup = this.createMarkup.bind(this);
	}

	async componentWillReceiveProps(nextProps: IAppProps){
		if(nextProps.match.params.nbr !== this.props.match.params.nbr){
			  //Perform some operation
			let nbr = nextProps.match.params.nbr;
			try {
				let r = await fetch('https://r2k0yhk5oi.execute-api.us-west-2.amazonaws.com/latest/api/song/'+nbr);
				let songs = await r.json();
				this.setState({ songs });
			} catch (error) {
				console.log(error);
			}
		  	
		}
	  }

	async componentDidMount() {
		const { match: { params } } = this.props;
		let nbr = this.props.match.params.nbr;
		
		try {
			let r = await fetch('https://r2k0yhk5oi.execute-api.us-west-2.amazonaws.com/latest/api/song/'+nbr);
			let songs = await r.json();
			this.setState({ songs });
		} catch (error) {
			console.log(error);
		}
		
	}

	createMarkup(input: string) {
		let tmp = input.replace("\\|", "<br");
		return {__html: tmp};
	  }
	  
	MyComponent(input: string) {
		return <div dangerouslySetInnerHTML={this.createMarkup(input)} />;
	}

	formatTextT(input: string) {
		
	return (<p className="small tmode" dangerouslySetInnerHTML={this.createMarkup(input)}></p>);
	}

	

	formatTextE(input: string) {
		
		return (<p className="small ntmode" hidden={true} dangerouslySetInnerHTML={this.createMarkup(input)} ></p>);
	}

	concatStr(input: string, input2: string) {
		return input+input2;
	}
	
	
	render() {
		return (
			<div className="container callsongs">
				{this.state.songs.map((song, index) => {
					return (
						<div key={index}>
							<Helmet>
								<meta charSet="utf-8" />
								<title>{`${song.song_title} - ${song.transliterated_title}`}</title>
								<meta name="description" content={`The Lyrics of the song is given here. ${song.lyrics_tamil} - ${song.lyrics_english}`} />
								<link rel="canonical" href={`https://www.mytamilsongs.net/songs/${song.song_book_nbr}`} />
							</Helmet>
							<h5 className="tmode" id="content"><strong>{song.song_title}</strong></h5>
							<h5 className="ntmode" hidden={true} id="content"><strong>{song.transliterated_title}</strong></h5>
							<p className="small">{song.song_desc}</p>
							
							{this.formatTextT(song.lyrics_tamil)}
							{this.formatTextE(song.lyrics_english)}
						</div>
					)
				})}
			</div>
		);
	}
}
 
export interface IAppProps {
	match: { params: {nbr: string} }
}

export interface IAppState {
	songs: Array<{id: string, song_title: string, lyrics_tamil: string, lyrics_english: string, 
	transliterated_title: string, song_desc: string, search_song: string, song_tags: string, song_book_nbr: string}>;
}
export default Onesong;