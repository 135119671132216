import * as React from 'react';
import {Helmet} from "react-helmet";

class Tagsongs extends React.Component<IAppProps, IAppState> {
	constructor(props: IAppProps) {
		super(props);
		this.state = { songs: []};
		this.formatTextT = this.formatTextT.bind(this);
		this.formatTextE = this.formatTextE.bind(this);
		this.concatStr = this.concatStr.bind(this);
		this.createMarkup = this.createMarkup.bind(this);
		this.getheadingT = this.getheadingT.bind(this);
		this.getheadingE = this.getheadingE.bind(this);
	}

	async componentWillReceiveProps(nextProps: IAppProps){
		if(nextProps.match.params.tag !== this.props.match.params.tag){
			  //Perform some operation
			let tag = nextProps.match.params.tag;
			try {
				let r = await fetch('https://r2k0yhk5oi.execute-api.us-west-2.amazonaws.com/latest/api/tagsongs/'+tag);
				let songs = await r.json();
				this.setState({ songs });
			} catch (error) {
				console.log(error);
			}

		  	
		}
	  }

	async componentDidMount() {
		const { match: { params } } = this.props;
		let tag = this.props.match.params.tag;
		
		try {
			let r = await fetch('https://r2k0yhk5oi.execute-api.us-west-2.amazonaws.com/latest/api/tagsongs/'+tag);
			let songs = await r.json();
			this.setState({ songs });
		} catch (error) {
			console.log(error);
		}
		
	}

	getheadingT(){
		let tag = this.props.match.params.tag;
		let result = "";
		
		if ("paamalai" === tag) {
			result = "பாமாலைகள்";
		} else if ("keerthanai" === tag) {
			result = "ஞான கீர்த்தனைகள்";
		} else if ("jebathottaJeyageethangal" === tag) {
			result = "ஜெபதோட்ட ஜெயகீதங்கள்";
		} else if ("callOfGod" === tag) {
			result = "தேவ அழைப்பு குறித்த பாடல்கள்";
		} else if ("praise" === tag) {
			result = "துதி பாடல்கள்";
		} else if ("worship" === tag) {
			result = "ஆராதனைப் பாடல்கள்";
		} else if ("prayer" === tag) {
			result = "ஜெபம் குறித்த  பாடல்கள்";
		} else if ("wordOfGod" === tag) {
			result = "வேதாகமம் குறித்த  பாடல்கள்";
		} else if ("repentanceAndConfession" === tag) {
			result = "பாவ அறிக்கை, மனம்திரும்புதல் குறித்த  பாடல்கள்";
		} else if ("faithAndAssurance" === tag) {
			result = "விசுவாசம், நம்பிக்கை குறித்த பாடல்கள்";
		} else if ("lifeInChristAndGuidance" === tag) {
			result = "இயேசுவில் வாழ்வு, வழிநடத்துதல் குறித்த பாடல்கள்";
		} else if ("graceAndBlessing" === tag) {
			result = "கிருபை, தேவாசீர்வாதம் குறித்த பாடல்கள்";
		} else if ("joy" === tag) {
			result = "சந்தோஷம்   குறித்த  பாடல்கள்";
		} else if ("loveAndFellowship" === tag) {
			result = "அன்பு, ஐக்கியம் குறித்த பாடல்கள்";
		} else if ("surrenderAndSanctification" === tag) {
			result = "ஒப்புக்கொடுத்தல், பரிசுத்தமாகுதல் குறித்த பாடல்கள்";
		} else if ("followingChrist" === tag) {
			result = "கிறிஸ்துவை பின்பற்றுதல் குறித்த பாடல்கள்";
		} else if ("evangelismAndMissions" === tag) {
			result = "நற்செய்தி பணி, மிஷனரி ஊழியம் குறித்த பாடல்கள்";
		} else if ("secondComing" === tag) {
			result = "இயேசுவின் இரண்டாம் வருகை, பரலோகம் குறித்த பாடல்கள்";
		} else if ("church" === tag) {
			result = "சபை குறித்த பாடல்கள்";
		} else if ("communion" === tag) {
			result = "திருவிருந்து குறித்த பாடல்கள்";
		} else if ("children" === tag) {
			result = "சிறுபிள்ளைகள்  குறித்த பாடல்கள்";
		} else if ("wedding" === tag) {
			result = "திருமணம்  குறித்த பாடல்கள்";
		} else if ("death" === tag) {
			result = "மரணம்  குறித்த பாடல்கள்";
		} else if ("newYear" === tag) {
			result = "புது வருடம்  குறித்த பாடல்கள்";
		} else if ("christmas" === tag) {
			result = "கிறிஸ்து பிறப்பு  குறித்த பாடல்கள்";
		} else if ("palmSunday" === tag) {
			result = "குருத்தோலை ஞாயிறு  குறித்த பாடல்கள்";
		} else if ("sufferingAndDeathOfChrist" === tag) {
			result = "கிறிஸ்து பாடு மரணம்  குறித்த பாடல்கள்";
		} else if ("resurrectionOfChrist" === tag) {
			result = "கிறிஸ்து உயிர்த்தெழுதல்  குறித்த பாடல்கள்";
		} else if ("farewell" === tag) {
			result = "பிரிவு உபசாரம்  குறித்த பாடல்கள்";
		}
		return result;
	}

	getheadingE(){
		let tag = this.props.match.params.tag;
		let result = "";
		
		if ("paamalai" === tag) {
			result = "Hymns";
		} else if ("keerthanai" === tag) {
			result = "Wisdom Lyrics";
		} else if ("jebathottaJeyageethangal" === tag) {
			result = "Jebathotta Jeyageethangal";
		} else if ("callOfGod" === tag) {
			result = "Songs on God's Call";
		} else if ("praise" === tag) {
			result = "Praise Songs";
		} else if ("worship" === tag) {
			result = "Worship Songs";
		} else if ("prayer" === tag) {
			result = "Songs on Prayer";
		} else if ("wordOfGod" === tag) {
			result = "Songs on Scripture";
		} else if ("repentanceAndConfession" === tag) {
			result = "Songs on Repentance and Confession";
		} else if ("faithAndAssurance" === tag) {
			result = "Songs on Faith and Assurance";
		} else if ("lifeInChristAndGuidance" === tag) {
			result = "Songs on Life in Christ and Guidance";
		} else if ("graceAndBlessing" === tag) {
			result = "Songs on Grace and Blessing of God";
		} else if ("joy" === tag) {
			result = "Songs on Joy";
		} else if ("loveAndFellowship" === tag) {
			result = "Songs on Love and Fellowship";
		} else if ("surrenderAndSanctification" === tag) {
			result = "Songs on Surrender and Sanctification";
		} else if ("followingChrist" === tag) {
			result = "Songs on Following Christ";
		} else if ("evangelismAndMissions" === tag) {
			result = "Songs on Evangelism and Missions";
		} else if ("secondComing" === tag) {
			result = "Songs on Second Coming of Jesus Christ and Heaven";
		} else if ("church" === tag) {
			result = "Songs on the Church";
		} else if ("communion" === tag) {
			result = "Songs on Holy Communion";
		} else if ("children" === tag) {
			result = "Songs on Children";
		} else if ("wedding" === tag) {
			result = "Songs on Wedding";
		} else if ("death" === tag) {
			result = "Songs on Death";
		} else if ("newYear" === tag) {
			result = "Songs on New Year";
		} else if ("christmas" === tag) {
			result = "Songs on Christmas";
		} else if ("palmSunday" === tag) {
			result = "Songs on Palm Sunday";
		} else if ("sufferingAndDeathOfChrist" === tag) {
			result = "Songs on Suffering and death of Christ";
		} else if ("resurrectionOfChrist" === tag) {
			result = "Songs on Ressurection of Christ";
		} else if ("farewell" === tag) {
			result = "Songs on Farewell";
		}
		return result;
	}

	createMarkup(input: string) {
		let tmp = input.replace("\\|", "<br");
		return {__html: tmp};
	  }
	  
	MyComponent(input: string) {
		return <div dangerouslySetInnerHTML={this.createMarkup(input)} />;
	}

	formatTextT(input: string) {
		
	return (<p className="small tmode" dangerouslySetInnerHTML={this.createMarkup(input)}></p>);
	}

	

	formatTextE(input: string) {
		
		return (<p className="small ntmode" hidden={true} dangerouslySetInnerHTML={this.createMarkup(input)} ></p>);
	}

	concatStr(input: string, input2: string) {
		return input+input2;
	}
	
	
	render() {
		return (
			<div className="container callsongs">
				<Helmet>
					<meta charSet="utf-8" />
					<title>{`${this.getheadingT()} - ${this.getheadingE()}`}</title>
					<meta name="description" content={`${this.getheadingT()} இங்கு தரப்பட்டுள்ளது. ${this.getheadingE()} are listed here. `} />
					<link rel="canonical" href={`https://www.mytamilsongs.net/tags/${this.props.match.params.tag}`} />
				</Helmet>
				<h5 className="mt-2 tmode" id="content"><strong>{this.getheadingT()}</strong></h5>
				<h5 className="mt-2 ntmode" hidden={true} id="content"><strong>{this.getheadingE()}</strong></h5>
				<p className="small tmode">{this.getheadingT()} இங்கு தரப்பட்டுள்ளது.</p>
				<p className="small ntmode" hidden={true} >{this.getheadingE()} are listed here.</p>
				
				<div id="accordion">{this.state.songs.map((song, index) => {
					return (
						<div key={index}>
							<div className="card mb-1 bg-light shadow">
								<div className="card-header " id={`songhead${song.id}`}>
								  <h6 className="mb-0 small"> 
									<button className="btn btn-sm btn-block collapsed cardbtn" data-toggle="collapse" data-target={`#songbody${song.id}`} aria-expanded="false" aria-controls={`songbody${song.id}`}>
										<span className="float-left tmode" ><strong>{song.song_title}</strong></span>
										<span className="float-left ntmode" hidden={true}><strong>{song.transliterated_title}</strong></span>
									</button>
								  </h6>
								</div>
							
								<div id={`songbody${song.id}`} className="panel-collapse collapse" aria-labelledby={`songhead${song.id}`} data-parent="#accordion">
								  <div className="card-body">
									<h5 className="tmode" id="content"><strong>{song.song_title}</strong></h5>
									<h5 className="ntmode" hidden={true} id="content"><strong>{song.transliterated_title}</strong></h5>
									<p className="small">{song.song_desc}</p>
									
									{this.formatTextT(song.lyrics_tamil)}
									{this.formatTextE(song.lyrics_english)}
									
								  </div>
								</div>
							</div>
						</div>
					)
				})}
				</div>
			</div>
		);
	}
}
 
export interface IAppProps {
	match: { params: {tag: string} }
}

export interface IAppState {
	songs: Array<{id: string, song_title: string, lyrics_tamil: string, lyrics_english: string, 
	transliterated_title: string, song_desc: string, search_song: string, song_tags: string, song_book_nbr: string}>;
	
}
export default Tagsongs;